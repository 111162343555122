import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Helmet } from "react-helmet"
import { AuthProvider } from "./src/auth/Auth"
import { FormStateProvider } from "./src/forms/form-state"
import { Vlibras } from "./src/landing-page/components/vlibras"
import favicon from "./src/landing-page/images/favicon.webp"
import ogImage from "./src/landing-page/images/og_image.webp"

import "./src/styles/global.css"

export const wrapRootElement = ({ element, props }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "pt-BR" }}>
        <html lang="pt-BR" />
        <title>Elo Feat dos Sonhos</title>
        <link rel="icon" href={favicon} type="image/x-icon" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:title" content="Elo Feat dos Sonhos" />
        <meta
          name="description"
          content="Inscreva-se no prêmio Elo Feat dos Sonhos e concorra a chance de gravar sua música e vídeo com grandes artistas do Brasil."
        />
        <meta
          property="og:description"
          content="Inscreva-se no prêmio Elo Feat dos Sonhos e concorra a chance de gravar sua música e vídeo com grandes artistas do Brasil."
        />
        {/* <meta http-equiv="Content-Security-Policy" content={csp} /> */}
        {/* <meta
          http-equiv="Permissions-Policy"
          content={`accelerometer=(), camera=(), microphone=(), geolocation=(), usb=()`}
        /> */}
      </Helmet>

      <AuthProvider>
        <FormStateProvider>
          <GoogleReCaptchaProvider
            container={{ parameters: { badge: "bottomleft", theme: "light" } }}
            reCaptchaKey={process.env.GATSBY_CAPTCHA_KEY || "not-set"}
            language="pt-BR"
          >
            {element}
          </GoogleReCaptchaProvider>
        </FormStateProvider>
        <Vlibras />
      </AuthProvider>
    </>
  )
}
