import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function onlyNumbers(str: string) {
  return str.replace(/\D/g, "")
}

export function JSONEquality(a: any, b: any) {
  const r = JSON.stringify(a) == JSON.stringify(b)

  return r
}

import { useCallback, useEffect, useMemo, useRef, useState } from "react"

function getErrorMessage(err: Error) {
  return err.message
}

export type UsePromise<T, U extends any[] = any[]> = {
  call: (...args: U) => Promise<T>
  callIfPristine: (...args: U) => Promise<T>
  data: T | undefined
  loading: boolean
  resetError: () => void
  resetData: () => void
  error: string | undefined
}

export function usePromise<T, U extends any[] = any[]>(fn: (...args: U) => Promise<T>) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any | undefined>(undefined)
  const [data, setData] = useState<T | undefined>(undefined)

  // setState is async, so "callIfPristine" will call more than once
  const justInvoked = useRef(false)

  const resetError = useCallback(() => {
    setError(undefined)
  }, [setError])

  const resetData = useCallback(() => {
    setData(undefined)
  }, [setData])

  useEffect(() => {
    justInvoked.current = false
  }, [loading])

  const call = useCallback(
    (...args: U) => {
      justInvoked.current = true
      setLoading(true)
      resetError()
      return fn(...args)
        .then((v) => {
          setData(v)
          return v
        })
        .catch((err: Error) => {
          setData(undefined)
          setError(err)
          throw err
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [setLoading, resetError, setData, fn, setError],
  )

  const callIfPristine = useCallback(
    (...args: U) => {
      if (data === undefined && !loading && !justInvoked.current) {
        return call(...args)
      }
    },
    [call, data, loading],
  )

  return useMemo(() => {
    return {
      call,
      callIfPristine,
      data,
      loading,
      resetError,
      resetData,
      error,
    } as UsePromise<T, U>
  }, [call, callIfPristine, resetData, data, loading, error, resetError])
}

export function nullToEmpty<T>(obj: T): T {
  const casted = obj as any
  return Object.keys(obj as any).reduce((acc, next) => {
    acc[next] = casted[next] === null ? "" : casted[next]
    return acc
  }, {} as any)
}

export function getDisplayFileName(url: string) {
  return new URL(url, "https://foo.com").pathname.split("-").findLast(() => true) || ""
}

export function normalizeFileName(url: string) {
  return new URL(url, "https://foo.com").pathname.slice(1)
}

export function acceptNumbers(e: React.KeyboardEvent) {
  if (
    e.code &&
    Number.isNaN(parseInt(e.key)) &&
    (e.code.startsWith("Key") || ["-", ".", ","].includes(e.key)) &&
    !e.altKey &&
    !e.metaKey
  ) {
    e.preventDefault()
  }
}

export function useStringified<T>(data: T): T {
  const stringified = JSON.stringify(data)

  const parsed = useMemo(() => {
    return JSON.parse(stringified)
  }, [stringified])

  return parsed
}
