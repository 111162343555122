export const FORM_ROUTES = {
  home: "/inscricao",
  login: "/inscricao/login/",
  forgot: "/inscricao/esqueci-senha/",
  signup: "/inscricao/cadastro/",
  step1: "/inscricao/dados-pessoais/",
  step2: "/inscricao/dados-artista/",
  step3: "/inscricao/representacao/",
  step4: "/inscricao/musica/",
  step5: "/inscricao/feat-dos-sonhos/",
  final: "/inscricao/revisao/",
  done: "/inscricao/minha-inscricao/",
}
