exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inscricao-cadastro-tsx": () => import("./../../../src/pages/inscricao/cadastro.tsx" /* webpackChunkName: "component---src-pages-inscricao-cadastro-tsx" */),
  "component---src-pages-inscricao-dados-artista-tsx": () => import("./../../../src/pages/inscricao/dados-artista.tsx" /* webpackChunkName: "component---src-pages-inscricao-dados-artista-tsx" */),
  "component---src-pages-inscricao-dados-pessoais-tsx": () => import("./../../../src/pages/inscricao/dados-pessoais.tsx" /* webpackChunkName: "component---src-pages-inscricao-dados-pessoais-tsx" */),
  "component---src-pages-inscricao-esqueci-senha-tsx": () => import("./../../../src/pages/inscricao/esqueci-senha.tsx" /* webpackChunkName: "component---src-pages-inscricao-esqueci-senha-tsx" */),
  "component---src-pages-inscricao-feat-dos-sonhos-tsx": () => import("./../../../src/pages/inscricao/feat-dos-sonhos.tsx" /* webpackChunkName: "component---src-pages-inscricao-feat-dos-sonhos-tsx" */),
  "component---src-pages-inscricao-index-tsx": () => import("./../../../src/pages/inscricao/index.tsx" /* webpackChunkName: "component---src-pages-inscricao-index-tsx" */),
  "component---src-pages-inscricao-login-tsx": () => import("./../../../src/pages/inscricao/login.tsx" /* webpackChunkName: "component---src-pages-inscricao-login-tsx" */),
  "component---src-pages-inscricao-minha-inscricao-tsx": () => import("./../../../src/pages/inscricao/minha-inscricao.tsx" /* webpackChunkName: "component---src-pages-inscricao-minha-inscricao-tsx" */),
  "component---src-pages-inscricao-musica-tsx": () => import("./../../../src/pages/inscricao/musica.tsx" /* webpackChunkName: "component---src-pages-inscricao-musica-tsx" */),
  "component---src-pages-inscricao-representacao-tsx": () => import("./../../../src/pages/inscricao/representacao.tsx" /* webpackChunkName: "component---src-pages-inscricao-representacao-tsx" */),
  "component---src-pages-inscricao-revisao-tsx": () => import("./../../../src/pages/inscricao/revisao.tsx" /* webpackChunkName: "component---src-pages-inscricao-revisao-tsx" */),
  "component---src-pages-libras-index-tsx": () => import("./../../../src/pages/libras/index.tsx" /* webpackChunkName: "component---src-pages-libras-index-tsx" */)
}

