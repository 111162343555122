import { FORM_ROUTES } from "@/constants/routes"
import { Amplify } from "aws-amplify"
import { AuthSession, fetchAuthSession, signOut } from "aws-amplify/auth"
import { navigate } from "gatsby"

import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react"

export function setupCognito() {
  return Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.GATSBY_COGNITO_POOL_ID || "not-set",
        userPoolClientId: process.env.GATSBY_COGNITO_CLIENT_ID || "not-set",
      },
    },
  })
}

type AuthContext = {
  session?: AuthSession | null
  refreshUser(): Promise<AuthSession>
  logout(): void
}

const Context = createContext<AuthContext>({
  logout() {},
  refreshUser() {
    console.warn("Called refreshUser outside the AuthProvider")
    return Promise.resolve(null as any)
  },
})

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<AuthContext["session"]>(undefined)

  useEffect(() => {
    setupCognito()

    refreshUser().catch(() => {})
  }, [])

  const refreshUser = useCallback(async () => {
    const data = await fetchAuthSession({ forceRefresh: true })
    setSession(data.tokens ? data : null)
    return data
  }, [setSession])

  const logout = useCallback(() => {
    signOut({ global: true }).then(() => {
      window.location.href = FORM_ROUTES.home
    })
  }, [])

  return <Context.Provider value={{ logout, session, refreshUser }}>{children}</Context.Provider>
}

export function useAuth() {
  return useContext(Context)
}
