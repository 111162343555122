import React from "react"
import { Script } from "gatsby"

export function Vlibras() {
    return (
    <>
        <div
            dangerouslySetInnerHTML={{
                __html: `
                <div vw class="enabled">
                    <div vw-access-button class="active"></div>
                    <div vw-plugin-wrapper>
                        <div class="vw-plugin-top-wrapper"></div>
                    </div>
                </div>
                `,
            }}
        />
        
        <Script
        src="https://cdn.jsdelivr.net/gh/spbgovbr-vlibras/vlibras-portal@dev/app/vlibras-plugin.js"
        onLoad={() => {
            new (window as any).VLibras.Widget("https://vlibras.gov.br/app");
            (window as any).onload();
        }}
        />
    </>
    )
}