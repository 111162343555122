import { InputMaybe, Register, RegisterInput } from "@/api.graphql.generated"
import { api, apiClient } from "@/api/GraphqlAPI"
import { useAuth } from "@/auth/Auth"
import { normalizeFileName } from "@/lib/utils"
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { TbLoader2 } from "react-icons/tb"

type RegisterState = Omit<Register, "id">

type ContextType = {
  ready: boolean
  register: RegisterState
  save(input: InputMaybe<RegisterInput>): Promise<RegisterState>
  finish(): Promise<RegisterState>
  loading: boolean
}

const Context = React.createContext<ContextType>({
  register: { finished: false },
  loading: false,
  ready: false,
  save: () => Promise.resolve({ finished: false }),
  finish: () => Promise.resolve({ finished: true }),
})

export function FormStateProvider({ children }: PropsWithChildren) {
  const { session } = useAuth()

  const [loading, setLoading] = useState(false)

  const [ready, setReady] = useState(false)

  const [register, setRegister] = useState<RegisterState>({ finished: false })

  const token = session?.tokens?.idToken
  useEffect(() => {
    apiClient.setHeader("Authorization", token ? "Bearer " + token : "")

    if (token) {
      api.Register({ input: { music: { musicRegisterDocuments: [] } } }).then((data) => {
        if (data.register) {
          setRegister(data.register)
          setReady(true)
        }
      })
    }
  }, [token])

  const save: ContextType["save"] = useCallback(
    (input) => {
      if (!input) {
        return Promise.resolve(register)
      }
      const cleanInput = { ...getDiff(input, register) }

      if (Object.keys(cleanInput).length === 0) {
        return Promise.resolve(register)
      }

      if (cleanInput.artisticImages) {
        cleanInput.artisticImages = cleanInput.artisticImages.map(normalizeFileName)
      }
      if (cleanInput.music?.usePermissionDocuments) {
        cleanInput.music.usePermissionDocuments = cleanInput.music.usePermissionDocuments.map(normalizeFileName)
      }
      if (cleanInput.representationDocuments) {
        cleanInput.representationDocuments = cleanInput.representationDocuments.map(normalizeFileName)
      }
      if (cleanInput.representationContractDocuments) {
        cleanInput.representationContractDocuments = cleanInput.representationContractDocuments.map(normalizeFileName)
      }
      if (cleanInput.representationAgencyDocuments) {
        cleanInput.representationAgencyDocuments = cleanInput.representationAgencyDocuments.map(normalizeFileName)
      }
      setLoading(true)
      return api
        .Register({ input: cleanInput })
        .then((ret) => {
          setRegister((v) => ({ ...v, ...ret.register }))
          return ret.register
        })
        .finally(() => setLoading(false))
        .catch((e) => {
          alert(
            "Ocorreu um erro ao salvar os dados. A página será recarregada. Caso o problema persista, entre em contato com nossa equipe.",
          )
          window.location.reload()
          throw e
        })
    },
    [register],
  )

  const finish = useCallback(() => {
    setLoading(true)
    return api
      .Finish()
      .then((data) => {
        setRegister((v) => ({ ...v, ...data.finishRegister }))
        return data.finishRegister
      })
      .finally(() => {
        setLoading(false)
      })
      .catch((e) => {
        alert(
          "Ocorreu um erro ao salvar os dados. A página será recarregada. Caso o problema persista, entre em contato com nossa equipe.",
        )
        window.location.reload()
        throw e
      })
  }, [])

  return (
    <Context.Provider
      value={{
        ready,
        register,
        save,
        finish,
        loading,
      }}
    >
      {children}

      {loading && (
        <div className="text-black bg-green p-2 text-xs rounded-full fixed z-50 top-10 right-2 flex items-center justify-center gap-2">
          <TbLoader2 className="animate-spin" />
          <span>Salvando...</span>
        </div>
      )}
    </Context.Provider>
  )
}

export function useFormState() {
  return useContext(Context)
}

function getDiff<T extends Record<string, any>>(input: T, data: any): Partial<T> {
  return Object.keys(input).reduce((acc, next) => {
    if (next === "music") {
      const musicDiff = getDiff(input.music || {}, data.music || {})
      if (Object.keys(musicDiff).length > 0) {
        acc.music = musicDiff
      }
      return acc
    }

    if (JSON.stringify(input[next] as any) != JSON.stringify(data[next])) {
      acc[next] = input[next] as any
    }
    return acc
  }, {} as any)
}
